<template>
  <b-modal
    id="modal-color-medicine"
    centered
    title="Nuevo color"
  >
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nombre*"
          label-for="name"
        >
          <b-form-input
            id="name"
            placeholder="Nombre"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Color*"
          label-for="color"
        >
          <b-form-input
            id="color"
            v-model="formData.color"
            type="color"
            placeholder="Color"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="12"
      >
        <b-form-textarea
          v-model="formData.description"
          placeholder="Descripción"
          rows="3"
        />
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
      <b-button
        type="button"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="save"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        color: '#000000',
        description: '',
      },
    }
  },
  validations: {
    formData: {
      color: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    save() {
      const me = this
      me.$emit('saveColor', me.formData)
    },
  },
}
</script>

<style lang="scss"></style>
