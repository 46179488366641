<template>
  <div>
    <b-card no-body>
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(is_active)="data">
          <div class="row">
            <b-badge
              v-if="data.item.is_active == 1"
              variant="success"
            >
              Activo
            </b-badge>
            <b-badge
              v-if="data.item.is_active == 0"
              variant="danger"
            >
              Inactivo
            </b-badge>
          </div>
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="patientmedicines-modal"
      ref="addpatientmedicines"
      title="Medicinas"
      hide-footer
      size="lg"
    >
      <b-card-text>
        <add-edit-medicine-form
          ref="form"
          :record="selectedRecord"
          :colors-medicine="colorsMedicine"
          :presentation-medicine="presentationMedicine"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
    <color-medicine-modal
      :is-busy="isSavingColor"
      @saveColor="saveColor"
    />
  </div>
</template>

<script>
import {
 BCard, BCardText, BModal, BBadge,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import CRUDMixin from '@/mixins/CRUDMixin'
import PermissionMixin from '@/mixins/PermissionMixin'
import AddEditMedicineForm from './AddEditMedicineForm'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import ColorMedicineModal from '../components/ColorMedicineModal'

export default {
	name: 'Medicines',
	inject: ['medicinesRepository'],
	components: {
		AddEditMedicineForm,
		BaseActionsTable,
		ColorMedicineModal,
		BCard,
		BCardText,
		BModal,
		BBadge,
	},
	mixins: [CRUDMixin, PermissionMixin],
	data() {
		return {
			fields: [
				{
					key: 'generic_name',
					label: 'Nombre genérico',
				},
				{
					key: 'commercial_name',
					label: 'Nombre comercial',
				},
				{
					key: 'concentration',
					label: 'Concentración',
				},
				{
					key: 'laboratory',
					label: 'Laboratorio',
				},
				{
					key: 'presentation.name',
					label: 'Presentación',
				},
				{
					key: 'is_active',
					label: 'Estado',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
			records: [],
			filter: null,
			filterOn: [],
			colorsMedicine: [],
			presentationMedicine: [],
			selectedRecord: null,
			repository: 'medicinesRepository',
			module: 'patientmedicines',
			isBusy: false,
			isSavingColor: false,
		}
	},

	async mounted() {
		const me = this
		await me.handleLoadData()
		me.loadColorsMedicine()
		me.loadPresentationMedicine()
	},

	methods: {
		handleCreating() {
			this.selectedRecord = null
			this.$bvModal.show('patientmedicines-modal')
		},

		handleEditing(record) {
			this.selectedRecord = record
			this.$bvModal.show('patientmedicines-modal')
		},

		handleDeleting(record) {
			this.del(record.id)
		},

		async loadColorsMedicine() {
			const me = this
			try {
				me.colorsMedicine = []
				const res = await me.medicinesRepository.getAllColor()
				const { data } = res
				data.forEach(element => {
					me.colorsMedicine.push(
						{
							...element,
							style: { backgroundColor: element.color },
						},
					)
				})
			} catch (error) {
				console.error(error)
			}
		},

		async loadPresentationMedicine() {
			const me = this
			try {
				const res = await me.medicinesRepository.getAllPresentation()
				me.presentationMedicine = res.data
			} catch (error) {
				// console.log(error)
			}
		},

		async saveColor(data) {
			const me = this
			try {
				me.isSavingColor = true
				const uuid = uuidv4()
				await me.medicinesRepository.saveColor(uuid, data)
				me.loadColorsMedicine()
				this.$bvModal.hide('modal-color-medicine')
			} catch (error) {
				console.error(error)
			} finally {
				me.isSavingColor = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
