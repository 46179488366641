<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nombre Generico*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.generic_name"
              placeholder="Nombre Genérico"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nombre Comercial"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.commercial_name"
              placeholder="Nombre Comercial"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Concentración*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.concentration"
              placeholder="Concentración"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Presentación*"
          >
            <b-form-select
              v-model="formData.presentation_id"
              :options="presentationMedicine"
              value-field="id"
              text-field="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Laboratorio*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.laboratory"
              placeholder="Laboratorio"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <b-form-select
              v-model="formData.is_active"
              :options="statuses"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-color-medicine
            variant="success"
            class="btn-icon"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
        <b-col
          md="12"
          class="mb-1"
        >
          <b-table
            :items="medicineColors"
            :fields="colorColumns"
            small
            bordered
          >
            <template #thead-top>
              <b-tr>
                <b-th colspan="3">
                  <h5 class="text-center py-1">
                    Codificación de colores para medicamentos
                  </h5>
                </b-th>
              </b-tr>
            </template>
            <template
              v-slot:cell(color)="row"
            >
              <div
                :style="'background-color: ' + row.item.color"
                class="py-1 pl-1"
              >
                <b-form-checkbox v-model="row.item.selected" />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
    BFormSelect,
    VBModal,
    BTable,
    BTr,
    BTh,
    BFormCheckbox,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    name: 'AddEditMedicineForm',
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BSpinner,
      BFormSelect,
      BTable,
      BTr,
      BTh,
      BFormCheckbox,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    mixins: [CRUDMixin],
    props: {
      record: {
        type: Object,
        default: () => {},
      },
      colorsMedicine: {
        type: Array,
        default: () => [],
      },
      presentationMedicine: {
        type: Array,
        default: () => [],
      },
    },
    inject: ['medicinesRepository'],

    data() {
      return {
        formData: {
          is_active: true,
          generic_name: '',
          commercial_name: '',
          concentration: '',
          laboratory: '',
          colors: '',
          presentation_id: '',
        },
        repository: 'medicinesRepository',
        isBusy: false,
        statuses: [
          {
            value: true,
            text: 'Activo',
          },
          {
            value: false,
            text: 'Inactivo',
          },
        ],
        colorColumns: [
          {
            key: 'color',
            label: 'Color',
            tdClass: 'p-0',
          },
          {
            key: 'description',
            label: 'Descripción',
          },
        ],
        medicineColors: [],
      }
    },

    watch: {
      medicineColors: {
        handler(val) {
          this.formData.colors = val.filter(item => item.selected === true).map(item => item.id).join(',')
        },
        deep: true,
      },
      colorsMedicine() {
        this.handleMedicineColors()
      },
    },

    mounted() {
      const me = this
      if (me.record) {
        me.formData = { ...me.record }
      }
      me.handleMedicineColors()
    },

    methods: {
      handleMedicineColors() {
        const me = this
        me.medicineColors = me.colorsMedicine.map(item => ({
          ...item,
          selected: false,
        }))

        if (me.record) {
          me.record.colors.split(',').forEach(item => {
            const colorIndx = me.medicineColors.findIndex(color => color.id === item)
            if (colorIndx != -1) {
              me.medicineColors[colorIndx].selected = true
            }
          })
        }
      },
    },

    validations: {
      formData: {
        is_active: {
          required,
        },
        generic_name: {
          required,
        },
        concentration: {
          required,
        },
        laboratory: {
          required,
        },
        presentation_id: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #636363;
  }
</style>
